/**
 * api接口统一管理
 */
import { getRequest, postJsonRequest, putJsonRequest, deleteRequest, putRequest } from './http'

export const userApi = {
  register: params => postJsonRequest('/user/register', params),
  loginByPwd: params => postJsonRequest('/user/login', params),
  login: params => postJsonRequest('/user/registerAndLogin', params),
  getUserInfo: () => getRequest('/user/getCurrentUser', {}),
  updateUserInfo: params => putRequest('/user/update', params),
  sendSmsCode: phone => postJsonRequest('/platform/sendMessage?phone=' + phone, {}),
  getUserInteraction: userId => getRequest('/user/queryUserPublicBriefInteraction?targetUserId=' + userId, {}),
  getByUserId: userId => getRequest(`/user/getUserById/${userId}`, {}),
  /// 获取用户动态
  getUserDynamicList: params => getRequest('/user/queryUserDynamic', params)
}

/**
 * 基础 api
 */
export const platformApi = {
  getCredentials: () => getRequest('/platform/getTempCredentials', {})
}

/**
 * 文章 api
 */
export const articleApi = {
  // 获取特殊文章列表（置顶列表）
  getSpecileArticles: params => postJsonRequest('articleSpecial/getArticlesBySpecialTypes', params),
  // 根据分类id获取文章列表
  getArticleListByClassificationId: params => getRequest('/article/queryArticles', params),
  // 获取文章互动信息
  getArticleInteractions: params => getRequest('/article/queryArticlePublicBriefInteraction', params),
  // 创建文章(帖子)
  createArticle: params => postJsonRequest('/article/createAnArticleAndBindClassificationsAndLabels', params),
  // 获取文章、帖子详情
  getArticleDetail: articleId => getRequest('/article/detail/' + articleId, {}),
  // 获取我参与互动的文章
  getMyInteractionArticles: params => getRequest('/article/pageMyInteractedArticles', params)
}

/**
 * 分类 api
 */
export const classificationApi = {
  // 获取分类列表
  getClassificationList: () => getRequest('/classification/getAllClassifications', {})
}

/**
 * 标签 api
 */
export const labelApi = {
  // 获取分类下的标签，如果分类id是空，那么获取全量的标签
  getLabelList: () => getRequest('/label/getLabelsByClassificationId', {})
}

/**
 * 互动 api
 */
export const interactionApi = {
  // 创建互动: 关注、收藏、点赞、浇水
  createInteraction: params => postJsonRequest('/interactionRecord', params),
  // 获取聊天的互动信息
  getChatInteraction: params => getRequest('/chat/interaction', params),
  // 删除互动
  delInteraction: interactionId => deleteRequest('/interactionRecord/' + interactionId, {}),
  // 获取我关注的用户
  getMyAttentions: params => getRequest('/interactionRecord/getAttentions', params),
  getFans: params => getRequest('/interactionRecord/getFans', params)
}

/**
 * 评论 api
 */
export const commentApi = {
  // 创建文章评论
  addArticleComment: (articleId, content, pictureList) => postJsonRequest('/chat/articleComment',
    {
      articleId: articleId,
      content: content,
      pictureList: pictureList
    }),
  // 修改评论
  updateArticleComment: (commentId, content, pictureList) => putJsonRequest('chat/update',
    {
      id: commentId,
      content: content,
      pictureList: pictureList
    }),
  // 删除评论
  delArticleComment: commentId => deleteRequest('/chat/' + commentId, {}),
  // 回复评论
  replyComment: (commentId, groupKey, content, pictureList) => postJsonRequest('/chat/commentReply', {
    parentId: commentId,
    groupKey: groupKey,
    content: content,
    pictureList: pictureList
  }),
  // 查看评论回复
  getReplyCommentList: groupKey => getRequest('/chat/commentReply?groupKey=' + groupKey, {}),
  // 获取文章评论列表
  getArticleCommentList: (currentPage, articleId) => getRequest(`/chat/articleComment/rootList/all?currentPage=${currentPage}&pageSize=10&articleId=${articleId}`, {}),
  // 文章热门评论
  getHotArticleCommentList: articleId => getRequest(`/chat/articleComment/rootList/hot?articleId=${articleId}`, {}),
  // 获取别人对我的所有评论
  getOthersCommentList: currentPage => getRequest(`/chat/commentsAboutMe?currentPage=${currentPage}&pageSize=10`, {})
}

/**
 * 举报 api
 */
export const reportApi = {
  // 创建举报
  addReport: params => postJsonRequest('/report', params)
}

/**
 * 搜索 api
 */
export const searchApi = {
  searchArticle: params => getRequest('/search/article', params),
  searchUser: params => getRequest('/search/user', params)
}

/**
 * 通知 api
 */
export const noticeApi = {
  // 获取通知列表
  getNoticeList: currentPage => getRequest(`/notice/pageList?currentPage=${currentPage}&pageSize=20`, {}),
  // 获取未读通知列表
  getUnreadNoticeList: params => getRequest('/notice/pageUnreadNotices', params),
  // 获取未读通知数量
  getUnreadNoticeCount: () => getRequest('/notice/countUnreadNotices', {})
  // 获取通知详情
}

/**
 * 埋点 api
 */
export const trackApi = {
  // 首次使用
  firstUsing: userAgent => postJsonRequest('/userEventLogs', {
    eventType: 'VIEW_WEBSITE',
    url: location.origin + '/pz',
    userAgent: userAgent
  }),
  /**
   * 文章埋点接口
   * LIKE_ARTICLE("LIKE_ARTICLE", "点赞文章"),
   * TRAMPLE_ARTICLE("TRAMPLE_ARTICLE", "点踩文章"),
    COMMENT("COMMENT", "评论（包含文章评论与回复评论）"),
    LIKE_COMMENT("LIKE_COMMENT", "点赞评论"),
    TRAMPLE_COMMENT("TRAMPLE_COMMENT", "点踩评论"),
    ATTENTION_USER("ATTENTION_USER", "关注用户"),
    STAR_ARTICLE("STAR_ARTICLE", "收藏文章"),
    PUBLISH_ARTICLE("PUBLISH_ARTICLE", "发布文章"),
    VIEW_ARTICLE_DETAIL("VIEW_ARTICLE_DETAIL", "浏览文章详情");
   */
  articleTrack: (evnetType, articleId, targetUserId, commentId) => postJsonRequest('/userEventLogs', {
    eventType: evnetType,
    articleId: articleId,
    targetUserId: targetUserId,
    commentId: commentId,
    url: location.origin + '/pz',
    userAgent: window.navigator.userAgent
  })
}
