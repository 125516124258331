<template>
  <div id="app">
    <template v-if="$route.meta.fullPage">
      <router-view v-if="!$route.meta.keepAlive" class="page-container"></router-view>
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" class="page-container"></router-view>
      </keep-alive>
    </template>
    <template v-else>
      <div class="el-menu-aside" :class="{ collapse: collapse }">
        <el-menu :default-active="activeMenu" :collapse="collapse">
          <el-menu-item index="0" class="menu-logo" disabled>
            <img alt="panyanzhi" class="animate-img" src="./assets/images/panyanzhi.png" width="40px" />
            <span slot="title" style="font-size: 24px;margin-left: 8px;font-weight: bold">{{ $t('site') }}</span>
          </el-menu-item>
          <el-menu-item index="/" @click="$router.push('/')">
            <i class="el-icon-menu" style="margin-left: 10px"></i>
            <span slot="title" class="tag-name">{{ $t('menuHome') }}</span>
          </el-menu-item>
          <el-menu-item index="/community" @click="$router.push('/community')">
            <i class="el-icon-s-management" style="margin-left: 10px"></i>
            <span slot="title" class="tag-name">{{ $t('menuCommunity') }}</span>
          </el-menu-item>
        </el-menu>
        <!-- 分割线 -->
        <div class="line">
          <p></p>
        </div>
        <el-menu :default-active="activeMenu" :collapse="collapse" class="tags-aside">
          <el-menu-item class="add-animate tag-item" v-for="tag, index in classifications" :key="'tag' + index"
            :index="'/tag/' + tag.id" @click="$router.push('/tag/' + tag.id)">
            <img :alt="tag.id" class="animate-img" :src="tag.picture" width="30px" style="margin-left: 10px" />
            <span slot="title" class="tag-name">{{ tag.name }}</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="page-header" :class="{ collapse: collapse }">
        <div>
          <div class="left">
            <div class="search-area">
              <span @click="jumpSearch" class="el-icon-search"></span>
              <el-input v-model="keywords" @change="jumpSearch" type="text" :placeholder="$t('keywordSearch')"
                size="small" style="width: 156px"></el-input>
            </div>
          </div>
          <div class="header-right">
            <lang style="margin-right: 20px"></lang>
            <login-menu v-if="isLogin"></login-menu>
            <div class="register-btn" v-else>
              <el-button @click="showLoginWindow('login')" type="primary" size="small">{{ $t('login')
                }}</el-button>
              <el-button @click="showLoginWindow('register')" type="primary" size="small">{{ $t('register')
                }}</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="page-content" :class="{ collapse: collapse }">
        <div class="page-container">
          <router-view v-if="!$route.meta.keepAlive"></router-view>
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
          </keep-alive>
        </div>
      </div>
      <el-backtop :right="20" :bottom="150"></el-backtop>
    </template>
    <!-- 登录弹窗 -->
    <el-dialog :title="loginType === 'login' ? $t('login') : $t('register')" :visible.sync="$store.state.loginVisible"
      width="320px" center :close-on-click-modal="false" :close-on-press-escape="false">
      <el-form ref="form" :model="form" style="margin-top: 20px">
        <el-form-item>
          <el-input v-model="form.phone" :placeholder="$t('input-name')"></el-input>
        </el-form-item>
        <el-form-item :class="{ 'mb-0': loginType === 'login' }">
          <el-input v-model="form.unencryptedPassword" :placeholder="$t('input-pwd')" show-password></el-input>
        </el-form-item>
        <el-form-item class="mb-0" v-if="loginType === 'register'">
          <el-input v-model="form.confirmPwd" :placeholder="$t('input-confirm-pwd')" show-password></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="$store.state.loginVisible = false">{{ $t('cancel') }}</el-button>
        <el-button @click="loginByPwd" type="primary">{{ $t('confirm') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Lang from '@/views/components/Lang.vue'
import { userApi, classificationApi, labelApi, trackApi } from '@/utils/api'
import LoginMenu from './views/user/components/LoginMenu.vue'
export default {
  name: 'App',
  components: { Lang, LoginMenu },
  data () {
    return {
      collapse: false,
      form: {
        phone: '',
        unencryptedPassword: ''
      },
      classifications: [],
      tags: [],
      keywords: '',
      activeMenu: '/',
      loginType: 'login'
    }
  },
  computed: {
    user: function () {
      return this.$store.state.user
    },
    isLogin () {
      return this.user.name !== '未登录'
    }
  },
  mounted () {
    this.updateWindowWidth()
    window.addEventListener('resize', this.updateWindowWidth)
    this.tags = this.getDataList()
    this.activeMenu = '/'
    this.openLogin()
    this.getClassifications()
    this.checkIfFirstVisitToday()
  },
  methods: {
    showLoginWindow (type) {
      this.$store.state.loginVisible = true
      this.loginType = type
    },
    checkIfFirstVisitToday () {
      const today = new Date().toLocaleDateString() // 获取今天的日期字符串
      const lastVisitDate = localStorage.getItem('lastVisitDate')

      if (!lastVisitDate || today !== lastVisitDate) {
        trackApi.firstUsing(window.navigator.userAgent).then(resp => {
          if (resp.httpStatus === 200) {
            localStorage.setItem('lastVisitDate', today) // 更新最后访问日期
          }
        })
      }
    },
    updateWindowWidth () {
      this.collapse = document.body.offsetWidth <= 1400
    },
    getClassifications: function () {
      if (this.$store.state.classifications.length > 0) {
        this.classifications = this.$store.state.classifications
        return
      }
      classificationApi.getClassificationList().then(resp => {
        if (resp.httpStatus === 200) {
          console.log('get classifications', resp)
          labelApi.getLabelList().then(resp2 => {
            if (resp2.httpStatus === 200) {
              console.log('get labels', resp2)
              resp.data.forEach(item => {
                item.labels = resp2.data.filter(item2 => item2.classificationId === item.id)
              })
              this.classifications = resp.data
              this.$store.commit('setClassifications', this.classifications)
            }
          })
        }
      })
    },
    getDataList: function () {
      const count = 20
      const list = []
      for (let i = 0; i < count; i++) {
        const item = { label: '标签' + i, icon: 'https://pic.iyingdi.com/admin/img/47f6a1fd-5242-4cbc-88be-a3e9bd4b9fc4.png', id: i }
        list.push(item)
      }
      return list
    },
    jumpSearch () {
      if (this.keywords.trim()) {
        window.open('/pz/search?key=' + this.keywords.trim(), '_blank')
      }
    },
    openLogin () {
      this.visible = true
    },
    validatePassword (password) {
      // 密码长度至少为8个字符
      if (password.length < 8) {
        this.$message.error(this.$t('pwd-too-short'))
        return false
      }

      // 检查密码是否包含至少一个字母和一个数字
      const hasLetter = /[a-zA-Z]/.test(password)
      const hasNumber = /\d/.test(password)

      if (!hasLetter || !hasNumber) {
        this.$message.error(this.$t('pwd-unvalid'))
        return false
      }

      // 如果所有检查都通过，则返回成功信息
      return true
    },
    loginByPwd () {
      const phone = this.form.phone
      const password = this.form.unencryptedPassword
      if (!phone || !password || !phone.trim() || !password.trim()) {
        return this.$message.warning(this.$t('phoneOrPwdError'))
      }
      if (!this.validatePassword(password)) {
        return
      }
      if (this.loginType === 'register') {
        // 注册：需要确认密码
        if (this.form.unencryptedPassword !== this.form.confirmPwd) {
          return this.$message.error(this.$t('pwd-not-match'))
        }
        userApi.register(this.form).then(resp => {
          if (resp.data) {
            localStorage.setItem('pc-token', resp.data.token)
            this.getUserInfo()
          } else {
            this.$message.error(this.$t('login-error'))
          }
        })
      } else {
        // 登录
        userApi.loginByPwd(this.form).then(resp => {
          if (resp.data) {
            localStorage.setItem('pc-token', resp.data.token)
            this.getUserInfo()
          } else {
            this.$message.error(this.$t('login-error'))
          }
        })
      }
    },
    login () {
      const phone = this.form.phone
      const verificationCode = this.form.verificationCode
      if (!phone || !verificationCode || !phone.trim() || !verificationCode.trim()) {
        return this.$message.warning(this.$t('phoneOrCodeError'))
      }
      userApi.login({ phone, verificationCode }).then(resp => {
        if (resp.data) {
          localStorage.setItem('pc-token', resp.data.token)
          this.getUserInfo()
        }
        console.log(resp)
      })
    },
    sendSmsCode () {
      const phone = this.form.phone
      if (!phone || !phone.trim()) {
        return this.$message.warning(this.$t('phoneError'))
      }
      userApi.sendSmsCode(phone).then(resp => {
        if (resp.success) {
          return this.$message.success(this.$t('sendedCode'))
        }
      })
    },
    getUserInfo () {
      userApi.getUserInfo().then(resp => {
        if (resp.data) {
          this.$store.commit('login', resp.data)
          this.visible = false
          location.reload()
        }
      })
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.updateWindowWidth)
  },
  watch: {
    $route: function () {
      this.activeMenu = this.$route.path
    }
  }
}
</script>

<style>
@import "../node_modules/element-ui/lib/theme-chalk/index.css";
@import "./assets/styles/reset.css";
@import "./assets/styles/style.css";

#app {
  display: flex;
}

.page-content {
  width: 100%;
  padding-top: 56px;
  padding-left: 250px;
}

.page-content.collapse {
  padding-left: 100px;
}

.page-content.bigger {
  width: calc(100vw - 64px);
}

.menu-logo {
  background: 0 0 !important;
  opacity: 1 !important;
  cursor: pointer !important;
  margin-bottom: 20px;
}

.page-header {
  height: 56px;
  background: rgb(236, 242, 251);
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  color: rgb(19, 128, 240);
  position: fixed;
  left: 250px;
  top: 0;
  z-index: 20;
  right: 0;
}

.page-header.collapse {
  left: 100px;
}

.page-header>div {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 1080px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-collapse,
.el-icon-user {
  font-size: 24px;
  cursor: pointer;
}

.user-info {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.el-icon-search {
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
}

.header-right {
  display: flex;
  align-items: center;
}

.search-area {
  display: flex;
  align-items: center;
}

.page-header .el-input__inner {
  border: none;
  background-color: transparent;
  color: #77baff;
  font-size: 14px;
  padding-left: 0;
}

.search-area .el-input__inner::placeholder {
  color: #77baff;
}

.tag-item img {
  margin-right: 28px;
}

.el-menu-aside {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  z-index: 30px;
}

.el-menu-aside.collapse {
  width: auto;
}

.el-menu-aside.collapse .el-menu--collapse {
  width: 100px;
}

.el-menu-aside .el-menu {
  border: none;
}

.line {
  padding: 10px 20px;
  background-color: white;
}

.line p {
  background-color: #f1f1f1;
  width: 100%;
  height: 1px;
}

.tags-aside {
  height: calc(100vh - 201px);
  overflow: auto;
  padding-bottom: 30px;
}

.header-right .el-input__inner {
  text-align: center;
}

.login-p {
  text-align: right;
}

.login-span-btn {
  cursor: pointer;
}

.login-span-btn:hover {
  color: rgb(19, 128, 240);
}

.mb-0 {
  margin-bottom: 0;
}
</style>
